@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Days+One&display=swap');


body,
html {
	font-family: 'Rubik', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roboto', sans-serif;
}

.fullscreen-page {
	min-height: 100dvh;
	background: rgb(52, 73, 94);
	background: radial-gradient(at top center, rgba(52, 73, 94, 1) 25%, rgba(44, 62, 80, 1) 50%);
	display: flex;
	flex-direction: column;
	@include waveBackground;
}

.flex {
	display: flex;
	flex-direction: row;
}

.grid-center {
	display: grid;
	place-items: center;
	height: 100%;
}