@media screen and (max-width:425px) {

  nav #text-logo {
    font-size: 12px !important;
  }

  nav .link-list .link-action {
    width: 48px !important;

    .link {
      font-size: 24px !important;
    }

    .link::before {

      $marginInline: 12px;
      width: 6px !important;
      left: calc(-1 * ($marginInline + (6px / 2))) !important;
    }
  }
}

nav {
  background-color: #1e1f22;
  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  #text-logo {
    font-family: "Days One", sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    color: #949ba4;
    margin: 0;
    padding: .5rem 0;
  }

  .link-list {
    margin: 0;
    padding: 0;
    list-style: none;

    hr.spacer {
      width: 42px;
      height: 3px;
      margin-block: 10px;
      border: none;
      background-color: #313338;
    }

    .link-action {
      width: 64px;

      $marginInline: 12px;
      border: none;
      outline: none;
      background-color: transparent;
      margin: 0 $marginInline 12px;
      padding: 0;
      display: block;



      .link {

        aspect-ratio: 1;


        background-color: #313338;
        font-size: 28px;
        border-radius: 50%;

        position: relative;
        cursor: pointer;
        transition: background-color 100ms linear,
          border-radius 100ms ease;


        .img-container {
          color: white;
          width: 100%;
          height: 100%;
          display: grid;
          place-items: center;
        }

        .link-title {
          display: none;

          position: absolute;
          white-space: nowrap;
          color: #c5cad0;
          background-color: #121214;
          font-size: 16px;
          padding: 10px 12px;
          font-family: 'Rubik', sans-serif;
          left: calc(100% + 20px);
          top: 0;
          border-radius: 5px;
          border-top-left-radius: 100px / 10px;

          transform-origin: left;
          animation: bounceIn 100ms linear forwards;

          &::after {
            content: '';
            width: 8px;
            height: 8px;
            background-color: inherit;
            position: absolute;
            left: -4px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);

          }
        }

        &::before {
          content: '';
          position: absolute;
          height: 20px;
          border-radius: 5px;
          background-color: white;
          width: 8px;
          left: calc(-1 * ($marginInline + (8px / 2)));

          transition: transform 200ms ease;
          transform: scale(0, .6);
        }

        &.active::before {
          height: 40px;
        }

        &.active,
        &:hover,
        &:focus-within {
          background-color: #5865F2;
          border-radius: 14px;

          &::before {
            transform: scale(1);
          }
        }

        &:hover .link-title,
        &:focus-within .link-title {
          display: block;
        }
      }
    }

  }
}

@keyframes bounceIn {
  0% {
    transform: scale(.92);
  }

  80% {
    transform: scale(1.04);
  }

  100% {
    transform: scale(1);
  }
}