#register-form {
  background-color: #313338;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 480px;
  max-width: 100vw;
  box-sizing: border-box;

  h2 {
    margin: 0;
    margin-bottom: 1rem;
    text-align: center;
    color: #fff;
    font-size: 24px;
  }

  label {
    // color: #949ba4;
    color: #B5BAC1;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .input-container {
    position: relative;
    display: grid;
    margin-top: 20px;
  }

  input {
    background-color: #1e1f22;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 18px;
    color: #fff;
    border-radius: 4px;
    font-weight: 200;
    box-sizing: border-box;
    width: 100%;
  }

  button[type="submit"] {
    background-color: #5865F2;
    border: none;
    outline: none;
    padding: 10px;
    font-size: 18px;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
    cursor: pointer;

    transition: background-color 100ms linear;
    font-size: 16px;
    font-weight: 400;

    &:hover:not(:disabled) {
      background-color: #4752c4;
    }

    &:disabled {
      cursor: default;
      background-color: #313338;
    }
  }

}

@media screen and (max-width:425px) {
  #register-form {
    height: 100%;
    border-radius: 0;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin-bottom: 0;
    }
  }
}