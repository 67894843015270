.users-container {
  background-color: #2b2d31;
  color: #949ba4;
  width: 250px;
  min-height: 100dvh;
  overflow: hidden;
  position: relative;


  .users {
    margin: 0;
    padding: 2rem 1rem;
    list-style: none;
    height: 100%;
    box-sizing: border-box;

    p.label {
      text-transform: uppercase;
      margin: 0;

      &:not(:first-of-type) {
        margin-top: 2rem;
      }
    }

    li {
      padding-left: .5rem;
      padding-top: .5rem;
      // color: #2ECC71;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &.offline {
        color: #949ba4;
        opacity: 0.6;
      }
    }
  }

  #server-invite-container {
    display: grid;
    padding: 1rem;
    position: absolute;
    bottom: 0;

    .input-label {
      font-size: 12px;
      text-align: left;
      text-transform: uppercase;
      font-weight: bold;
      margin: 0;
      margin-bottom: 4px;
    }

    .input-container {
      display: flex;
      position: relative;

      input {
        box-sizing: border-box;
        width: 100%;
      }

      input,
      button {
        background-color: #1e1f22;
        border: none;
        padding: 10px;
        font-size: 18px;
        color: #fff;
        border-radius: 4px;
        font-weight: 200;
      }
    }
  }
}

.group-chat-container {
  width: 100%;
  height: 100dvh;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  background-color: #313338;

  #message-container {
    overflow-y: auto;
    padding: .5rem 1rem;

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      background-color: #292b2f;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #18191c;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
    }

    .message {

      p {
        margin: 0;

        margin-top: 0.25rem;
        color: white;
        font-size: 20px;
        font-family: "Roboto", sans-serif;
      }

      p.name {
        font-weight: bold;
        margin-top: 1rem;
      }

      p.text {
        padding-left: 2px;
      }

      .date {
        padding-left: .5rem;
        font-size: 14px;
        color: #949ba4;
      }
    }

  }

  #input-form {
    display: flex;
    padding: 8px;
    margin: .5rem;
    margin-top: 0;
    border-radius: 8px;
    background-color: #43464d;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    input {
      width: 100%;
      border: none;
      outline: none;
      font-size: 20px;
      background-color: transparent;
      color: white;
    }

    button {
      color: white;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 24px;
      cursor: pointer;

      &:disabled {
        cursor: default;
        opacity: 0.6;
      }
    }
  }
}

@media screen and (max-width:768px) {
  .users-container {
    display: none;
  }
}